<template>
  <div>
    <div class="head-area">
      <div class="head-label">
        <div style="padding-top: 80px;">
          高级人才寻访
        </div>
        <p style="font-size: 16px;padding-bottom: 50px;padding-top: 10px">利用专业的人才寻访渠道和高端人才资源库进行人才寻访、面试、测评，精准高效地为客户寻访到最合适的顶尖人才，缩短寻访时间，降低招聘成本</p>
      </div>
      <div class="head-btn">
        <div @click="addneed()" style="background-color: #0db88a;padding: 10px 30px;border-radius: 2px;cursor:pointer;">
          发布需求
        </div>
      </div>
    </div>
    <div style="width: 1440px;margin: 0 auto;background-color: white">
      <img :src="png_search1">
      <img :src="png_search2">
      <div style="width: 1440px;height: 518px">
        <div class="title">我们的客户</div>
        <div class="intro">我们已经为百余家企业提供了职业素养、技能管理、人力资源等方面的优质培训，遍布金融、地产、汽车等行业</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in customer" style="width: 228px;height: 80px;margin-bottom: 20px">
          </div>
        </div>
<!--        <img :src="png_labor3" alt="" style="">-->
      </div>
    </div>
  </div>
</template>

<script>
import png_head from '@/assets/images/company/companyHead.jpg'
import png_search1 from '@/assets/images/company/TalentSearch1.png'
import png_search2 from '@/assets/images/company/TalentSearch2.png'
import logo from '@/assets/images/company/Train_logo1.png'
import png_labor3 from '@/assets/images/company/labor3.png'
import {TalentSearch_url, baseURL} from "@/utils/request/apis";

export default {
  name: "CompanyTrain",
  data(){
    return{
      png_head,
      png_search1,
      png_search2,
      logo,
      png_labor3,
      customer:[],
      baseURL :'',
    }
  },
  created() {
    this.baseURL = baseURL;
    this.getListData()
  },
  methods: {
    addneed(){
      const url = this.$router.resolve({ name: 'talentsearchform' })
      window.open(url.href)
    },
    getListData(){
      var caseTemp = '';
      var customerTemp = '';
      this.$http.get(TalentSearch_url).then(res => {
        if(res.success){
          console.log("1111",res);
          const temp = res.result.records;
          for(var i = 0 ; i < temp.length ; i++){
            // if(temp[i].type == 0){
            //   caseTemp += temp[i].image;
            //   caseTemp += ','
            // }
            if(temp[i].type == 3){
              customerTemp += temp[i].image
              customerTemp += ','
            }
          }
          // if(caseTemp.endsWith(',')){
          //   caseTemp = caseTemp.substring(0,caseTemp.length-1);
          // }
          if(customerTemp.endsWith(',')){
            customerTemp = customerTemp.substring(0,customerTemp.length-1);
          }
          // this.case = caseTemp.split(',');
          this.customer = customerTemp.split(',');
          // console.log("case",this.case);
          console.log("customer",this.customer);
        } else {
          this.$message(res.message);
        }
      })
    },
  }
}
</script>

<style scoped>
.head-area{
  width: 100%;
  height: 300px;
  background: white;
  padding: 0px;
  background-image: url('../../assets/images/company/companyHead.jpg');
  background-size: 100% 100%;
}
.head-label {
  /*display:flex;*/
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /*line-height:240px;*/
  font-size:30px;
}
.head-btn {
  display:flex;
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /* background-color:#0db88a; */
  /* width: fit-content; */
  font-size:13px;
  margin-top: 0px;
}
.title{
  margin-top: 60px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: #303133;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.intro{
  margin-top: 8px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #606266;
}
.company-list{
  width: 1200px;
  margin: 0 auto;
  margin-top: 32px
}
.logo-list{
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
</style>
